import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../settings/countries";
import { getCurrentURLSearchParams } from "../../../../utils/browser-features";
import { experiments, isExperimentDefaultVariant } from "../../../../utils/experiments";
import { shouldAskForPhoneNumberBeforePetParentPage } from "../../../../utils/locale-configuration-utils";

interface UseQuoting {
  nextUrl: string | null;
  isCountdownOver: boolean;
  isWaitingForUserInteraction: boolean;
  setIsWaitingForUserInteraction: Dispatch<SetStateAction<boolean>>;
  isFormSubmitted: boolean;
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>;
  hasQuotingForm: boolean | undefined;
}

const useQuoting = (): UseQuoting => {
  const result = useResult();
  const [nextUrl, setNextUrl] = useState<null | string>(null);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [isWaitingForUserInteraction, setIsWaitingForUserInteraction] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const hasQuotingForm =
    shouldAskForPhoneNumberBeforePetParentPage(result?.country as CountryCode) &&
    !isExperimentDefaultVariant(experiments.askForPhoneNumberBeforePetParentPage) &&
    result?.hasHealthQuote;

  useEffect(() => {
    const currentURLSearchParams = getCurrentURLSearchParams();
    const noEditable = currentURLSearchParams?.get("no_editable");

    if (!result) {
      return;
    }

    if (result?.hasOnlyGlQuote) {
      setNextUrl(`/results/${result?.uuid}/details/?gl_only=1`);
    } else {
      setNextUrl(`/results/${result?.uuid}/${noEditable ? `?no_editable=1` : ""}`);
    }

    const timer = setTimeout(() => {
      if (nextUrl && !isWaitingForUserInteraction) {
        setIsCountdownOver(true);
      }
    }, 9000);

    return () => clearTimeout(timer);
  }, [isWaitingForUserInteraction, nextUrl, result]);

  return {
    nextUrl,
    isCountdownOver,
    isWaitingForUserInteraction,
    setIsWaitingForUserInteraction,
    isFormSubmitted,
    setIsFormSubmitted,
    hasQuotingForm,
  };
};

export default useQuoting;
